// NEW COLORS FOR DARK THEME
$primary-black: #000000;
$primary-black-16: #292929;
// new colors
$primary-black: #000000;
$primary-black-11: #1c1c1c;
$primary-black-15: #262626;
$primary-black-16: #292929;
$primary-black-17: #2c2c2c;
$black16: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), #000000;
$black20: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #000000;
$black26: linear-gradient(0deg, rgba(255, 255, 255, 0.26), rgba(255, 255, 255, 0.26)), #000000;
$black1: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #000000;
$primary-white: #ffffff;
$input-background: #292929;
$red: #fa114f;
$red-hover: #f51b56;
$red-focused: #f52e64;
$red-shadow: 0px 2px 4px -1px rgb(250 17 79 / 20%), 0px 4px 5px 0px rgb(250 17 79 / 14%),
  0px 1px 10px 0px rgb(250 17 79 / 12%);
$primary-green: #00ff41;
$primary-green-hover: #59ff49;
$primary-green-focused: #59ff54;
$primary-green-shadow: 0px 2px 4px -1px rgb(0 255 65 / 20%), 0px 4px 5px 0px rgb(0 255 65 / 14%),
  0px 1px 10px 0px rgb(0 255 65 / 12%);
$contrast-text-dark: #000;
$contrast-text-secondary-dark: #fff;
$green: #00ad45;
$main-border: rgba($primary-white, 0.6);
$main-disabled: rgba($primary-white, 0.12);
// --------------------------------------------------

$disabled-color-dark: rgba(255, 255, 255, 0.38);
$divider-color-dark: rgba(255, 255, 255, 0.12);
$error-light-dark: #e57373;
$error-main-dark: #f44336;
$error-dark-dark: #d32f2f;
$login-divider-dark: #ffffffde;
$border-badge-dark: #a0a0a0;
$primary-main-dark: #70b5f9;
$primary-background-dark: #1e1e1e;
$text-secondary-dark: rgba(255, 255, 255, 0.87);
$sweep-gradient-dark: linear-gradient(-135deg, #1bee02 0.56%, $green 100%);
$sweep-lighter-dark: #1bee02;
$sweep-box-shadow-dark: 0px 2px 4px -1px rgba(0, 173, 69, 0.2), 0px 4px 5px 0px rgba(0, 173, 69, 0.14),
  0px 1px 10px 0px rgba(0, 173, 69, 0.12);
$gold-gradient-dark: linear-gradient(-135deg, #ffb300 4.17%, #f57c00 96.87%);
$gold-color-dark: #f57c00;
$gold-lighter-dark: #ffb300;
$gold-box-shadow-dark: 0px 2px 4px -1px rgba(245, 124, 0, 0.2), 0px 4px 5px 0px rgba(245, 124, 0, 0.14),
  0px 1px 10px 0px rgba(245, 124, 0, 0.12);
$default-gradient-dark: #00ff41;
$default-color-dark: #00ff41;
$default-lighter-dark: #fe139c;
$default-box-shadow-dark: 0px 2px 4px -1px rgba(199, 28, 184, 0.2), 0px 4px 5px 0px rgba(199, 28, 184, 0.14),
  0px 1px 10px 0px rgba(199, 28, 184, 0.12);

// contrast colors
$contrast-text-secondary-87-dark: rgba(255, 255, 255, 0.87);
$contrast-text-secondary-80-dark: rgba(255, 255, 255, 0.8);
$contrast-text-secondary-60-dark: rgba(255, 255, 255, 0.6);
$contrast-text-secondary-54-dark: rgba(255, 255, 255, 0.54);
$contrast-text-secondary-50-dark: rgba(255, 255, 255, 0.5);
$contrast-text-secondary-38-dark: rgba(255, 255, 255, 0.38);
$contrast-text-secondary-23-dark: rgba(255, 255, 255, 0.23);
$contrast-text-secondary-12-dark: rgba(255, 255, 255, 0.12);
$contrast-text-secondary-4-dark: rgba(255, 255, 255, 0.04);
$text-primary-80-dark: rgba(255, 255, 255, 0.8);
$text-primary-70-dark: rgba(255, 255, 255, 0.7);
$default-lighter-30-dark: rgba($default-lighter-dark, 0.3);
$default-color-8-dark: rgba($default-color-dark, 0.08);
$sweep-color-8-dark: rgba($green, 0.08);
$gold-color-8-dark: rgba($gold-color-dark, 0.08);
$spinner-color: #ffc107;
