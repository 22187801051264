@import 'src/styles/config';
.root {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: $spacing0d75Rem;
}

.input {
  margin-bottom: $spacing0d125Rem;
  margin-top: $spacing0d125Rem;
  width: 100%;

  input {
    font-size: 14px;
    font-family: inherit;
  }

  label {
    color: get(contrast-text-secondary-54);
  }

  fieldset {
    border-color: get(contrast-text-secondary-23);
  }
}

.half {
  width: calc((100% / 2) - 4px);
}

.validationText {
  margin: 0;
  line-height: 1rem;
}

.checkbox {
  margin-top: 7px;
  margin-left: auto;
  margin-right: auto;
}

.label {
  font-size: 0.75rem;
  color: get(contrast-text-secondary-60);
}

.agreeField {
  margin-left: auto;
  margin-right: auto;
}

.button {
  margin-top: 8px;
  margin-bottom: 24px;
  width: 100%;
  height: 48px;
  font-size: 16px;

}

.promoteBanner {
  margin-bottom: 20px;
}
