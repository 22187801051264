@import 'src/styles/config';
.root {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 18px 0 16px;
  width: 100%;

  &::after,
  &::before {
    content: '';
    display: flex;
    flex-grow: 1;
    height: 2px;
    background: $base100;
  }
}

.text {
  margin: 0 8px;
  font-size: 14px;
  text-align: center;
  color: $base90;
}
