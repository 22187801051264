@import 'src/styles/config';
.root {
  margin: 0 auto 1rem;
  padding: 16px;
  justify-content: space-between;
  max-width: 27rem;
  position: relative;
  color: $primary-white;
  padding-top: calc($headerHeight + env(safe-area-inset-top));

  // mobile
  @media screen and (max-width: $sm) {
    width: 100%;
    height: 100%;
    max-width: none;
    display: flex;
    flex-direction: column;
    padding: calc($headerHeight - $spacing0d5Rem + env(safe-area-inset-top)) $spacing0d75Rem $spacing0d75Rem
      $spacing0d75Rem;
  }
}

.contained {
  color: $textColorPrimary;
  background: $body-color;
}

.caption {
  position: relative;
  width: auto;
  margin-left: -16px;
  margin-right: -16px;
}

.header {
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.icon {
  width: 126px;
  max-height: 16px;
}

.logoContainer {
  display: flex;
  align-items: center;
  min-width: 166px;

  @media (max-width: $sm) {
    min-width: auto;
  }

  @media (orientation: landscape) {
    margin-left: env(safe-area-inset-left);
  }
}
