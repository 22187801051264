@import 'src/styles/config';
.subTitle {
  margin: 8px auto 24px;
  max-width: 240px;
  text-align: center;
}

.link {
  color: $base100;
  cursor: pointer;
  margin-left: 0.3rem;
  text-decoration: underline;
}

.forgotPass {
  cursor: pointer;
  margin: 0 auto 16px;
  text-decoration: underline;
  color: $base100;
  display: flex;
  justify-content: center;
  font-weight: $font-weight-500;
  font-size: 14px;
  line-height: 140%;
  border: none;
  background: none;

  @media screen and (min-width: $sm) {
    margin-bottom: 80px;
  }
}

.forgotPassLnk {
  margin: 0 0 32px;
  text-decoration: none;
  cursor: pointer;
}

.accountLink {
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  flex: 1 1;
  margin-top: 26px;
  color: $base90;

  @media screen and (max-width: $xs) {
    margin-bottom: 24px;
  }
}

.loginContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 400px;
  margin: 0 auto;

  @media screen and (min-width: $md) {
    padding-top: 10px;
  }
}
