@import 'src/styles/config';
.root {
  & :global {
    .mt-input-base,
    .mt-input-focus,
    .mt-input-error,
    .mt-input-error.mt-input-focus,
    .mt-input-outlined:hover:not(.mt-input-focus),
    .mt-input-outlined:hover:not(.mt-input-error) {
      &::after {
        border: unset;
      }
    }

    .mt-input-outlined {
      &:hover:not(.mt-input-focus)::after,
      &:hover:not(.mt-input-error)::after {
        @media (any-pointer: coarse) {
          border: unset;
        }
      }
    }

    .mt-input-focus {
      input {
        border-color: $base100;
      }
    }

    .mt-input-error {
      & input,
      &.mt-input-focus input {
        border-color: $base80;
      }
    }
  }

  label {
    display: inline-block;
    font-size: $fontSM;
    line-height: 140%;
    color: $base100;
    padding: 0 0 $spacing0d25Rem 0;
  }
}
