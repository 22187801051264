@import 'src/styles/config';
.root {
  &::after {
    left: 0;
  }

  &::before {
    left: 0;
  }
}

.root.GC {
  background-color: #fbb400;

  &:hover {
    background: darken(#fbb400, 10%);
  }

  &:focus {
    background: darken(#fbb400, 15%);
  }
}

.root.SC {
  color: $base100;
  background-color: #7fb800;

  &:hover {
    background: darken(#7fb800, 10%);
  }

  &:focus {
    background: darken(#7fb800, 15%);
  }
}

.root[role='button']:focus-visible {
  outline: 1px auto #3492f0;
}

.loading {
  &::before {
    content: '';
    color: #fff;
    margin-right: 8px;
    width: 16px;
    height: 16px;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-top-color: #ffffff;
    border-left-color: #ffffff;
    border-radius: 50%;
    animation: button-loading-spinner 0.7s linear infinite;
  }
}

@keyframes button-loading-spinner {
  to {
    transform: rotate(360deg);
  }
}
