@import 'src/styles/config';
$banner-offset: 6px;
$banner-arrow-horizontal-offset: 60%;
$banner-arrow-vertical-offset: 50%;

.root {
  :global(.prev-banner-arrow) {
    top: $banner-arrow-vertical-offset;
    transform: translate(-$banner-arrow-horizontal-offset, -$banner-arrow-vertical-offset);
  }

  :global(.next-banner-arrow) {
    top: $banner-arrow-vertical-offset;
    transform: translate($banner-arrow-horizontal-offset, -$banner-arrow-vertical-offset);
  }
}

.scrollContainer {
  display: grid;
  grid-auto-columns: 100%;
  padding: 0;
  margin-left: -$banner-offset;
  margin-right: -$banner-offset;
}

.bannerTile {
  width: 100%;
  scroll-snap-align: center;
  padding-top: 28%;
  position: relative;

  .bannerTile-image {
    object-position: bottom;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;
    height: auto;
  }
}
