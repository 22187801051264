@import 'src/styles/config';
.root {
  width: 100%;
  min-height: 56px;
  background: $brandAccentSecondary;
  color: $base80;

  &:hover {
    background: $brandAccentSecondary75;
    color: $base80;
  }

  &:focus {
    background: $brandAccentSecondary50;
    color: $base80;

    &:hover {
      background: $brandAccentSecondary75;
      color: $base80;
    }
  }

  &:disabled {
    color: $brandAccentSecondary;
    cursor: inherit;
    background: $brandAccentSecondary25;

    &:hover {
      box-shadow: none;
    }
  }
}

.loading {
  &::before {
    border-top-color: #697cc0;
    border-left-color: #697cc0;
  }

  padding: 0;
  background: get(contrast-text-secondary-12);

  &:disabled {
    background: get(contrast-text-secondary-12);
  }

  &:hover {
    box-shadow: none;
  }
}

.icon {
  width: 24px;
  height: 24px;
  display: block;
  margin-right: 8px;
}

.text {
  font-size: $fontLG;
  font-weight: $font-weight-500;
}
