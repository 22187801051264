@import 'src/styles/config';
.validationText {
  margin: 4px 0 4px 8px;
  line-height: 1rem;
  font-size: 12px;
  color: $base100;
}

.icon {
  position: relative;
  margin-right: 0;
  height: $sizing3Rem;
  top: 2px;
  padding: 16px;
}

.passwordIcon {
  color: $base100;
  margin-right: 0;
  height: $sizing3Rem;
  top: $spacing0d125Rem;
  padding: 16px;

  & svg {
    height: $sizing1Rem;
    width: $sizing1Rem;
  }
}

.notOnlyAdornment {
  padding: 16px 0 16px 16px;
}
