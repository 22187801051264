@import 'src/styles/config';
.recaptcha {
  margin: 1rem auto;
}

.fakeRecaptcha {
  display: flex;
  align-items: center;
  width: 300px;
  height: 74px;
  background-color: white;
  margin: 0 auto;
  color: black;

  svg {
    height: 30px;
    width: 30px;
    fill: black;
  }
}
