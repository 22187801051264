@import './default-theme';
@import './dark-theme';
@import './colors';
@import './variables';
@import './mixins';

// colors for light and dark themes
$themes: (
  default: (
    error-light: $error-light-dark,
    error-main: $error-main-dark,
    error-dark: $error-dark-dark,
    disabled-color: $disabled-color-dark,
    divider-color: $divider-color-dark,
    login-divider: $login-divider-dark,
    primary-main: $primary-main-dark,
    border-badge: $border-badge-dark,
    sweep-gradient: $sweep-gradient-dark,
    sweep-lighter: $sweep-lighter-dark,
    sweep-box-shadow: $sweep-box-shadow-dark,
    gold-gradient: $gold-gradient-dark,
    gold-color: $gold-color-dark,
    gold-lighter: $gold-lighter-dark,
    gold-box-shadow: $gold-box-shadow-dark,
    default-gradient: $default-gradient-dark,
    default-color: $default-color-dark,
    default-lighter: $default-lighter-dark,
    default-box-shadow: $default-box-shadow-dark,

    // contrast colors
    contrast-text-secondary-87: $contrast-text-secondary-87-dark,
    contrast-text-secondary-80: $contrast-text-secondary-80-dark,
    contrast-text-secondary-60: $contrast-text-secondary-60-dark,
    contrast-text-secondary-54: $contrast-text-secondary-54-dark,
    contrast-text-secondary-50: $contrast-text-secondary-50-dark,
    contrast-text-secondary-38: $contrast-text-secondary-38-dark,
    contrast-text-secondary-23: $contrast-text-secondary-23-dark,
    contrast-text-secondary-12: $contrast-text-secondary-12-dark,
    contrast-text-secondary-4: $contrast-text-secondary-4-dark,
    text-primary-80: $text-primary-80-dark,
    text-primary-70: $text-primary-70-dark,
    default-lighter-30: $default-lighter-30-dark,
    default-color-8: $default-color-8-dark,
    sweep-color-8: $sweep-color-8-dark,
    gold-color-8: $gold-color-8-dark,
  ),
) !default;
$desktopMenuWidth: 280px !default;
$mobileMenuHeight: 56px !default;
$headerHeight: $sizing5Rem !default;
$mobileHeaderHeight: 64px !default;
$stickyBarHeight: 34px !default;
$headerHeightMobile: 48px !default;
$headerHeightMobileLandscape: 48px !default;
$tabsHeight: 48px !default;
$jackpotBottomBarHeight: 40px !default;
$jackpotBottomBarHeightMobile: 40px !default;
$red-500: #f44336 !default;
$green-600: #43a047 !default;
$amber-700: #ffa000 !default;
$TRANSITION_TIME: 250ms !default;

// radius
$shapeBorderRadius: 4px !default;

// shadows
$shadow-1: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12) !default;
$shadow-2: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12) !default;
$shadow-4: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !default;
$shadow-8: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
  0px 3px 14px 2px rgba(0, 0, 0, 0.12) !default;
$iphone5Size: 320px;

// breakpoints
$xs: 927px !default;
$sm: 933px !default;
$md: 992px !default;
$lg: 1200px !default;
$xlg: 1440px !default;
$xl: 1920px !default;
$maxContentWidth: 1160px;

// gray
$gray: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #d5d5d5,
  A200: #aaaaaa,
  A400: #303030,
  A700: #616161,
) !default;
$imgPath: 'https://storage.googleapis.com/www.jackpota.com/jackpota-images';
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-500: 500;
$font-weight-600: 600;
$font-weight-700: 700;
$font-weight-800: 800;
$font-weight-900: 900;
